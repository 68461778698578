/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';
import { HeadLine } from '../../components/component';
import Meta from '../../components/Meta';
import useGetCollections from '../../hooks/useGetCollections';
import { HorizontalCollectionFilter } from '../../components/filter/HorizontalCollectionFilter';
import { OnEnterScreen } from '../../components/onEnterScreen';
import { useRouter } from 'next/router';
import qs from 'query-string';
import { Explore_collection_item_table } from '../../components/collectrions/explore_collection_table';
import { useAccount } from 'wagmi';
import { useGetWatchlist } from '../../hooks/useWatchlist';
import { BsBookmarkHeart } from 'react-icons/bs';
import { Tab, Tabs } from '@mui/material';
import TabPanel from '../../components/TabPanel';
import { WrappedCollectionsSvg } from '../../components/WrappedCollectionsSvg';

const tabItem = [
  {
    id: 1,
    text: 'Collections',
    icon: 'collections',
  },
  {
    id: 2,
    text: 'Wrapped Collections',
    icon: <WrappedCollectionsSvg />,
  },
  {
    id: 3,
    text: 'Watchlist',
    icon: 'watchlist',
  },
];
const INITIAL_FILTER = {
  sort: {
    id: 'order:asc',
    text: 'Default',
  },
  categories: { id: 1, text: 'All Categories' },
  chainIds: [],
};
const Explore_collection = ({
  search,
  disableFilters,
  size,
  disablePagination,
  additionalFilter,
  disableTabs = false,
}) => {
  const [page, setPage] = useState(1);
  const [filterVal, setFilterVal] = useState(INITIAL_FILTER);
  let filter = search ? { search } : filterVal;

  const [tab, setTab] = useState(0);

  if (additionalFilter) filter = { ...filter, ...additionalFilter };

  const { data: swr, isLoading } = useGetCollections({
    filter: { ...filter, isWrapped: false },
    limit: size || 50,
    page,
  });
  const { data: mirrorCollectionsData } = useGetCollections({
    filter: { ...filter, isWrapped: true },
    limit: 500,
  });
  const { results: data, page: lastPage, totalPages } = swr || {};
  const { results: mirrorCollections } = mirrorCollectionsData || {};
  const [lastData, setLastData] = useState([]);
  const [shouldLoad, setShouldLoad] = useState({});
  const router = useRouter();

  const { address } = useAccount();
  const { data: getWatchlist } = useGetWatchlist(address, true);

  useEffect(() => {
    if (data && lastData && lastPage !== 1) {
      const merged = [...lastData, ...data];
      const unique = merged.filter(
        (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i,
      );
      return setLastData(unique);
      // return setLastData([...lastData, ...data]);
    }
    if (data && lastPage == 1) {
      setLastData(data);
    }
  }, [data]);
  useEffect(() => {
    if (lastPage < totalPages) setPage(lastPage + 1);
  }, [shouldLoad]);
  useEffect(() => {
    setPage(1);
  }, [filterVal]);

  useEffect(() => {
    const parsed = qs.parse(window.location.search);
    Object.keys(parsed).forEach((key) => {
      if (typeof parsed[key] === 'string') {
        try {
          parsed[key] = JSON.parse(parsed[key]);
        } catch (e) {}
      }
    });
    setFilterVal({ ...INITIAL_FILTER, ...parsed });
  }, [router]);

  useEffect(() => {
    if (router.pathname.indexOf('user') > -1) {
      return;
    }
    const query = {
      ...filterVal,
    };
    Object.keys(query).forEach((key) => {
      if (typeof query[key] === 'object') {
        query[key] = JSON.stringify(query[key]);
      }
    });
    const newQuery = qs.stringify(query, {
      arrayFormat: 'bracket',
      skipEmptyString: true,
      skipNull: true,
    });

    if (
      qs.stringify(router.query, {
        arrayFormat: 'bracket',
        skipEmptyString: true,
        skipNull: true,
      }) !== newQuery
    ) {
      if (JSON.stringify(INITIAL_FILTER) !== JSON.stringify(filterVal)) {
        router.replace(`${router.pathname}?${newQuery}`, undefined, { shallow: false });
      }
    }
  }, [filterVal]);

  const getIcon = (icon) => {
    if (icon === 'watchlist') {
      return <BsBookmarkHeart className='icon mr-1 mt-1 h-5 w-5 fill-current' />;
    } else if (typeof icon === 'string') {
      return (
        <svg className='icon mr-2 mt-1 h-5 w-5 fill-current'>
          <use xlinkHref={`/icons.svg#icon-${icon}`} />
        </svg>
      );
    } else {
      return icon;
    }
  };

  return (
    <>
      <Meta title='Explore Collection || Bit5 | Create. Collect. Connect.' />
      <section className={disableFilters ? 'relative pb-24' : 'relative pb-24 lg:pb-48'}>
        {!disableFilters && (
          <picture className='pointer-events-none absolute inset-0 -z-10 dark:hidden'>
            <img src='/images/gradient_light.jpg' alt='gradient' className='h-full' />
          </picture>
        )}

        <div className='container px-2'>
          {!disableFilters && (
            <HeadLine
              text='Explore Collections'
              classes='font-display text-jacarta-700 py-16 text-center text-4xl font-medium dark:text-white'
            />
          )}
          {!disableTabs && (
            <div className='flex justify-center overflow-x-auto'>
              <Tabs
                value={tab}
                onChange={(e, value) => {
                  setTab(value);
                }}
                variant='scrollable'
                scrollButtons='auto'
                classes={{
                  flexContainer: 'flex flex-nowrap',
                  root: 'h-16',
                  indicator: '!bg-jacarta-700 dark:!bg-white',
                }}
              >
                {tabItem.map(({ id, text, icon }) => {
                  return (
                    <Tab
                      disableRipple
                      key={id}
                      label={text}
                      className='font-display text-base font-medium normal-case'
                      classes={{
                        labelIcon: '',
                        root: 'hover:text-jacarta-700 text-jacarta-400 dark:text-white',
                        selected: '!text-jacarta-700 dark:!text-white',
                      }}
                      icon={getIcon(icon)}
                    />
                  );
                })}
              </Tabs>
            </div>
          )}
          <TabPanel value={tab} index={0}>
            {!disableFilters && (
              <HorizontalCollectionFilter filterObject={filterVal} onChange={setFilterVal} />
            )}
            <div className='w-full'>
              {lastData?.length == 0 && !isLoading ? (
                <div className={'ml-2'}>No Results Found!</div>
              ) : (
                <Explore_collection_item_table
                  itemFor='explore-collection'
                  collectionData={lastData}
                />
              )}

              <OnEnterScreen
                onVisible={() => {
                  setShouldLoad({});
                }}
              />
            </div>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {!disableFilters && (
              <HorizontalCollectionFilter filterObject={filterVal} onChange={setFilterVal} />
            )}
            <div className='w-full'>
              {mirrorCollections?.length == 0 && !isLoading ? (
                <div className={'ml-2'}>No Results Found!</div>
              ) : (
                <Explore_collection_item_table
                  itemFor='explore-collection'
                  collectionData={mirrorCollections}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            {getWatchlist?.length == 0 ? (
              <div className={'ml-2'}>No Results Found!</div>
            ) : (
              <Explore_collection_item_table
                itemFor='explore-collection'
                collectionData={getWatchlist}
              />
            )}
          </TabPanel>
        </div>
      </section>
    </>
  );
};

export default Explore_collection;
